<template>
  <div class="authentication">
    <div class="content">
      <div class="title" v-if="location !== '陕西省'">
        <h3>兰州市残疾人就业创业网络服务平台 | 信息认证</h3>
        <router-link to="/">返回首页</router-link>
      </div>
      <div class="title" v-else>
        <h3>信息认证</h3>
        <router-link to="/shanxi/">返回首页</router-link>
      </div>
      <div class="install">
        <h1>企业信息完善</h1>
        <p>为验证企业真实性，请您提交相关信息</p>
        <el-form
            :model="form"
            :rules="error"
            label-width="100px"
            ref="form"
            class="form"
        >
          <el-form-item label="企业名称" prop="name">
            <el-input
                v-model="form.name"
                class="wrap_input"
                @change="getCompanyByName"
                placeholder="请输入与营业执照一致的公司全称"
            ></el-input>
          </el-form-item>
          <el-form-item label="注册地" prop="registerProvinced">
            <ThreeLink
                :codeType="'provinceid'"
                :codeLevel="'3'"
                placeholder="请选择工作地点"
                class="width100"
                @setMValue="
                setScasValue($event, form, [
                  'registerProvinced',
                  'registerCity',
                  'registerThreecity',
                ])
              "
                :defaultValue="[
                form.registerProvinced,
                form.registerCity,
                form.registerThreecity,
              ]"
            ></ThreeLink>
          </el-form-item>
          <el-form-item label="详细地址" prop="registerDetail">
            <el-input
                v-model="form.registerDetail"
                class="wrap_input"
                placeholder="请输入公司的详细注册地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人姓名" prop="linkman">
            <el-input
                v-model="form.linkman"
                class="wrap_input"
                placeholder="请输入业务联系人姓名"
            ></el-input>
          </el-form-item>
          <div class="tips" v-if="getLoginShow">
            <div class="tip_content">
              该公司已被注册。
              <router-link :to="'/companyAudit?name='+form.name">
                忘记账号密码？立即找回>>
              </router-link>
            </div>
          </div>
        </el-form>

        <div class="btn">
          <!-- <el-button type="primary" @click="submit" >立即提交</el-button> -->
          <el-button type="primary" @click="submit('form')" :loading="loadingbut">{{
              loadingbuttext
            }}
          </el-button>
          <el-button type="text" class="no_err" @click="isHide = true"
          >暂不完善
          </el-button
          >
        </div>
      </div>
    </div>
    <el-dialog
        :visible.sync="isShow"
        width="312px"
        :show-close="false"
        class="wrap_input"
        :before-close="handleClose"
    >
      <div class="dialog_content" v-if="active == 0">
        <img src="~/static/login/success.png" alt=""/>
        <h3>认证成功</h3>
        <p>恭喜您通过认证，可以获取更多服务啦</p>
        <el-button type="primary">返回({{ time }}S)</el-button>
      </div>
      <div class="dialog_content" v-if="active == 1">
        <img src="~/static/login/error.png" alt=""/>
        <h3>认证失败</h3>
        <p>{{ message }}</p>
        <el-button type="primary">返回({{ time }}S)</el-button>
      </div>
    </el-dialog>
    <el-dialog
        :visible.sync="isHide"
        width="312px"
        :show-close="false"
        :before-close="handleClose"
    >
      <div class="dialog_content">
        <img src="~/static/login/resume.png" alt=""/>
        <h3>
          未通过认证的用户 <br/>
          无法享受企业专属服务哦
        </h3>
        <div class="btn flex">
          <el-button type="info" @click="goRouter('/')">残忍拒绝</el-button>
          <el-button type="primary" @click="isHide = false">去认证</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ThreeLink from "@/components/selectComponents/ThreeLink";

export default {
  name: "authentication",
  components: {ThreeLink},
  inject: ["reload"],
  data() {
    return {
      loadingbut: false,
      loadingbuttext: "立即提交",
      form: {
        name: "",
        registerProvinced: "",
        registerCity: "",
        registerThreecity: "",
        registerDetail: "",
        linkman: "",
        registerWay: 1,
      },
      error: {
        name: [{required: true, message: "请输入企业全称", trigger: "blur"}],
        registerProvinced: [
          {required: true, message: "请选择公司注册地址", trigger: "change"},
        ],
        registerDetail: [
          {required: true, message: "请输入详细地址", trigger: "blur"},
        ],
        linkman: [
          {required: true, message: "请输入联系人姓名", trigger: "blur"},
        ],
      },
      isShow: false,
      isHide: false,
      active: 1,
      time: 3,
      message: "很遗憾未通过认证，请检查后重新提交",
      location: '',
      getLoginShow:false,
    };
  },
  methods: {
    submit(form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          this.loadingbut = true;
          this.loadingbuttext = "提交中...";
          let res = await this.$api.companyAuthenticationApi(this.form);
          if (res.data.success) {
            this.active = 0;
            this.info();
            this.$router.push("/companyLogin/authenticationrz");
            // this.$confirm(
            //     "继续完善资料获取更丰富的服务",
            //     "",
            //     {
            //       confirmButtonText: "继续完善",
            //       cancelButtonText: "暂不完善，重新登录随便看看",
            //       type: "warning",
            //       customClass: 'messageBox'
            //     }
            // ).then(() => {
            //   this.$router.push("/companyLogin/authenticationrz");
            // }).catch(() => {
            //   this.getOut();
            // })
          } else {
            this.active = 1;
            this.isShow = true;
            this.message = res.data.msg || '很遗憾未通过认证，请检查后重新提交';
            this.time = 3;
            this.times();
          }
          this.loadingbut = false;
          this.loadingbuttext = '立即提交';
        } else {
          this.$message.error('您有必填信息未填写！');
        }
      });
    },
    //根据公司名查询公司是否已注册
   async getCompanyByName(val) {
      let res = await this.$api.getCompanyByNameApi({compName:val});
      if(res.data.success){
        this.$message.warning(res.data.msg);
        this.getLoginShow = true;
      }else{
        this.getLoginShow = false;
      }
    },
    handleClose() {
      this.isShow = false;
      this.isHide = false;
      if (this.active == 0) {
        this.info();
      }
    },
    times() {
      if (this.time > 0) {
        this.time--;
        setTimeout(this.times, 1000);
      } else {
        this.time = 0;
        this.handleClose();
      }
    },
    goRouter(url, id) {
      const {href} = this.$router.resolve({
        path: url,
        id: id,
      });
      window.location.href = href;
    },
    setScasValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
      }
    },
    async info() {
      let res = await this.$api.loginRefreshApi();
      if (res.data.success) {
        let user = res.data.data;
        this.$store.commit("SET_USER_INFO", user);
        this.reload();
        this.$router.push("/companyLogin/authenticationrz");
      } else {
        this.$message.error("登录失败");
      }
    },
    async getOut() {
      this.$store.commit("SET_USER_INFO", 1);
      this.$store.commit("SET_TOKEN", "");
      this.$store.commit("GET_USER", "");
      localStorage.removeItem("access_token");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("userType");
      this.$router.push("/user/companyLogin");
    },
  },
  mounted() {
    this.form.memberId = JSON.parse(localStorage.getItem("userType")).id;
  },
  created() {
    this.location = JSON.parse(localStorage.getItem('userType')) || "";
  },
};
</script>

<style scoped lang="less">
/deep/ .el-message-box {
  padding-bottom: 30px !important;
}

.authentication {
  width: 100%;
  min-height: 100vh;
  background-color: white;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 366px;

  .content {
    width: 1200px;
    margin: 0 auto;

    .title {
      display: flex;
      padding-top: 26px;

      h3 {
        flex: 1;
        font-size: 18px;
        color: #28a46d;
      }

      a {
        font-size: 18px;
        color: #66716c;
      }

      a:hover {
        color: #28a46d;
      }
    }

    .install {
      width: 60%;
      margin: 84px auto 0 auto;
      text-align: center;

      h1 {
        font-size: 28px;
        color: #00120a;
        margin-bottom: 10px;
      }

      p {
        font-size: 16px;
        color: #66716c;
      }

      /deep/ .form {
        width: 450px;
        margin: 36px auto 0 80px;

        .el-form-item {
          .el-input__inner {
            width: 336px;
          }

          .el-input__inner::placeholder {
            font-size: 14px;
            color: #99a09d;
          }
        }
      }

      .btn {
        margin-top: 100px;

        .el-button {
          display: block;
          margin: 0 auto;
          width: 336px;
        }

        .no_err {
          color: #99a09d;
        }
      }
    }
  }

  .el-dialog {
    .dialog_content {
      text-align: center;

      h3 {
        font-size: 18px;
        margin-top: 24px;
      }

      p {
        color: #66716c;
        padding: 6px 0;
      }

      .btn {
        justify-content: center;
        margin-top: 10px;

        .el-button {
          width: 102px;
        }

        .el-button:nth-of-type(1) {
          margin-right: 20px;
        }
      }

      .el-button {
        margin-top: 16px;
        width: 132px;
      }
    }
  }

  /deep/ .el-cascader {
    .el-input__inner {
      background: rgba(0, 0, 0, 0.03);
    }
  }
  .tips{
    margin:20px 0;
    .tip_content{
      line-height: 1.8em;
      color: #666666;
      a{
        color: #28A46D;
      }
    }
  }
}
</style>
